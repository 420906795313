const shuffleArray = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const arraysAllowedToTick = (side, originalArray) => {
  if (side === "Full") { return [...Array(128).keys()]; }
  let finalArray = [];
  let startIndex = 0;
  let endIndex = 0;
  let maxStartIndex = 0;
  let maxEndIndex = 0;
  let divideIndex = 0;
  let addIndex = 0;
  switch (side) {

    case "Left":
      startIndex = 1;
      endIndex = 8;
      maxStartIndex = 113;
      maxEndIndex = 120;
      divideIndex = 8;
      addIndex = 16;
      break;
    case "Right":
      startIndex = 9;
      endIndex = 16;
      maxStartIndex = 121;
      maxEndIndex = 128;
      divideIndex = 8;
      addIndex = 16;
      break;
    case "Top":
      startIndex = 1;
      endIndex = 16;
      maxStartIndex = 49;
      maxEndIndex = 64;
      divideIndex = 16;
      addIndex = 16;
      break;
    case "Bottom":
      startIndex = 65;
      endIndex = 80;
      maxStartIndex = 113;
      maxEndIndex = 128;
      divideIndex = 16;
      addIndex = 16;
      break;
    case "Middle":
      startIndex = 5;
      endIndex = 12;
      maxStartIndex = 117;
      maxEndIndex = 122;
      divideIndex = 8;
      addIndex = 16;
      break;

  }
  for (let index = 0; index < originalArray.length; index++) {
    let runningIndex = index + 1;
    if (runningIndex >= startIndex && runningIndex <= endIndex) {
      finalArray.push(index);
      if (finalArray.length % divideIndex === 0) {
        if (startIndex + 16 <= maxStartIndex && endIndex + 16 <= maxEndIndex) {
          startIndex = startIndex + 16;
          endIndex = endIndex + 16;
        }


      }
    }
  }
  return finalArray;
}

const sleep = (milliseconds) => {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds) {
      break;
    }
  }
}

const getRowColumnIndex = (index, totalRows, totalColumns) => {
  let tempIndex = index + 1;
  let tempRow = 1;
  let tempColumn = tempIndex;
  let tempTotalColumns = totalColumns;
  while (tempIndex > tempTotalColumns) {
    tempTotalColumns = tempTotalColumns + totalColumns;
    tempRow = tempRow + 1;
    tempColumn = tempColumn - totalColumns;

  }


  return { row: tempRow, column: tempColumn }

}

const findColor = (x, y) => {
  if (x === 1 || y === 1) {
    return "white";
  } else {
    return "#9C27B0";
  }
}


export { shuffleArray, arraysAllowedToTick, sleep, getRowColumnIndex, findColor }