import React, { Fragment, useEffect, useState } from "react";

export default function LiveCapture(props) {

  const [copies, setCopies] = useState([]);
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(function (stream) {
        if (document.getElementById("videoTrainer")) {
          document.getElementById("videoTrainer").srcObject = stream;
          document.getElementById("videoTrainer").play();
        }

      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
    return function cleanup() {
      if (document.getElementById("videoTrainer") && document.getElementById("videoTrainer").srcObject) {
        document.getElementById("videoTrainer").srcObject.getTracks().forEach(function (track) {
          // stopping every track
          track.stop();
        });
        // assign null to srcObject of video
        document.getElementById("videoTrainer").srcObject = null;
      };
    }
  }, []);

  // useEffect (()=>{if(copies.length > 3){
  // videoTrainer.srcObject.getTracks().forEach(function(track) {
  //    track.stop();
  // });
  // videoTrainer.srcObject = null;
  // }},[copies])

  const takepicture = () => {
    let context = document.getElementById("canvasTrainer").getContext('2d');
    document.getElementById("canvasTrainer").width = 320;
    document.getElementById("canvasTrainer").height = 300;
    context.drawImage(document.getElementById("videoTrainer"), 0, 0, 320, 300);

    let data = document.getElementById("canvasTrainer").toDataURL('image/png');

    setCopies(cops => { let newCops = [...cops]; newCops.push(data); return newCops; });
    //photoTrainer.setAttribute('src', data);
    //clearphoto();

  }
  return (
    <Fragment>
      <video style={{ "display": "none" }} id="videoTrainer">Video stream not available.</video>
      {/* <button onClick= {takepicture}>Take photo</button> */}
      <canvas style={{ "display": "none" }} id="canvasTrainer">
      </canvas>
      {/* <div class="output">
  {copies.map(k=> <img src={k} alt="The screen capture will appear in this box."/>)}
  
  </div>    */}
    </Fragment>
  );
}
