import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import App from "./App";
import GameSetting from "./compnents/GameSettingComponent";

function AppRouter() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/game/">About</Link>
            </li>
            
          </ul>
        </nav> */}

        <Route path="/" exact component={GameSetting} />
        {/* <Route path="/game/" component={App} /> */}
      </div>
    </Router>
  );
}

export default AppRouter;