import {
  Box,
  Button, CheckBox, Grommet,
  RangeInput, Select, Table,
  TableBody, TableCell, TableRow, TextInput
} from 'grommet';
import { ChapterNext } from 'grommet-icons';
import React, { Component } from 'react';
import App from '../App';
import OutputPage from '../compnents/OutputPage';
import * as PictureUtil from '../utils/PictureUtil';
const theme = {
  global: {
    colors: {
      brand: '#9C27B0',
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
  },
};
class GameSetting extends Component {
  constructor() {
    super();
    this.state = {
      "traineeName": "",
      "pageType": "START_PAGE",
      "inspectionType": "Left",
      "tileRunSpeed": 150,
      "pictureWaitSpeed": 1000,
      "nextRoundWaitSpeed": 2000,
      "numberOfRounds": 10,
      "isImageTick": true,
      "resultList": [],
      "pictureType": "ANIMALS",
      "capturedImagesArray": []
    }
  }
  stateUpdate = (value, type) => {
    this.setState({ [type]: value });
  }



  render() {


    if (this.state.pageType === "GAME_PAGE") {
      return <App
        backButtonClick={() => { this.setState({ "pageType": "START_PAGE" }) }}
        nextRoundWaitSpeed={this.state.nextRoundWaitSpeed}
        pictureWaitSpeed={this.state.pictureWaitSpeed}
        tileRunSpeed={this.state.tileRunSpeed}
        inspectionType={this.state.inspectionType}
        numberOfRounds={this.state.numberOfRounds}
        pictureType={this.state.pictureType}
        isImageTick={this.state.isImageTick}
        resultPageChange={
          (resultList, capturedImagesArray) => {
            this.setState({ "pageType": "RESULT_PAGE", resultList, capturedImagesArray })
          }} >
      </App>
    }
    else if (this.state.pageType === "RESULT_PAGE") {
      //return ReactPDF.render(<TestPage />, '/example.pdf');

      return <OutputPage backButtonClick={() => { this.setState({ "pageType": "START_PAGE" }) }} resultData={this.state}></OutputPage>
    } else {

      return <Grommet
        align='center'
        theme={{
          global: {
            colors: { doc: '#ff99cc' }, font: {
              family: 'Trebuchet MS',
              size: '14px',
              height: '20px',
            }
          }
        }}
      > <Box fill align='center'
        direction="column">
          <h1 style={{ marginTop: '2%' }} ><strong >Training Settings</strong></h1>

          <Box
            align='center'
            direction="column"
            justify="center"
            border={{ color: 'brand', size: 'small' }}
            pad="large"
            responsive
            width='large'
            alignSelf='center'
            style={{ marginTop: '5%' }}          >
            <Table alignSelf='center'>
              <TableBody >
                <TableRow>
                  <TableCell scope="row">
                    <strong>* Trainee Name:</strong>
                  </TableCell>
                  <TableCell> <Box direction="row">
                    <TextInput
                      value={this.state.traineeName}
                      placeholder="Please Enter Trainee Name"
                      onChange={event => this.stateUpdate(event.target.value, "traineeName")}
                    /></Box></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Position:</strong>
                  </TableCell>
                  <TableCell> <Select
                    size="small"
                    options={['Full', 'Middle', 'Left', 'Right', 'Top', 'Bottom']}
                    value={this.state.inspectionType}
                    onChange={({ option }) => this.stateUpdate(option, "inspectionType")}
                  /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Category :</strong>
                  </TableCell>
                  <TableCell> <Select
                    size="small"
                    options={['RANDOM', 'ANIMALS', 'CITIES', 'PROFESSIONS', 'SINGERS', 'HOUSE_HOLD_OBJECTS', 'LOGOS', 'PRESIDENTS', 'FLOWERS', 'BIRDS', 'FLAGS']}
                    value={this.state.pictureType}
                    onChange={({ option }) => this.stateUpdate(option, "pictureType")}
                  /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" >
                    <strong>Scrolling Delay:</strong>
                  </TableCell>
                  <TableCell> <Box direction="row"><RangeInput
                    min={50}
                    max={1000}
                    step={10}

                    value={this.state.tileRunSpeed}
                    onChange={(event) => this.stateUpdate(event.target.value, "tileRunSpeed")}
                  /> <span>{this.state.tileRunSpeed}</span></Box></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Preview Delay:</strong>
                  </TableCell>
                  <TableCell> <Box direction="row"><RangeInput
                    min={50}
                    max={1000000}
                    step={10}
                    value={this.state.pictureWaitSpeed}
                    onChange={(event) => this.stateUpdate(event.target.value, "pictureWaitSpeed")}
                  /> <span>{this.state.pictureWaitSpeed}</span></Box></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Image StandBy Time:</strong>
                  </TableCell>
                  <TableCell> <Box direction="row"><RangeInput
                    min={1000}
                    max={100000}
                    step={500}
                    value={this.state.nextRoundWaitSpeed}
                    onChange={(event) => this.stateUpdate(event.target.value, "nextRoundWaitSpeed")}
                  /> <span>{this.state.nextRoundWaitSpeed}</span></Box></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Number of Rounds :</strong>
                  </TableCell>
                  <TableCell> <Box direction="row">
                    <TextInput
                      value={this.state.numberOfRounds}
                      type="number"
                      onChange={event => this.stateUpdate(event.target.value, "numberOfRounds")}
                    /></Box></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <strong>Image Tick :</strong>
                  </TableCell>
                  <TableCell> <Box direction="row">
                    <CheckBox
                      checked={this.state.isImageTick}
                      onChange={event => this.stateUpdate(event.target.checked, "isImageTick")} />
                  </Box></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell scope="row">

                  </TableCell>
                  <TableCell><Button
                    primary
                    icon={<ChapterNext />}
                    disabled={!this.state.traineeName.trim().length > 0}
                    label="Enter Game"
                    onClick={() => { if (this.state.pictureType === "RANDOM") { PictureUtil.randomSelection(); } this.setState({ "pageType": "GAME_PAGE" }) }}
                  /></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Grommet>
    }

  }
}
export default GameSetting;
