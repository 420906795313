import {
  Box, Image
} from 'grommet';
import React, { Component } from 'react';
import * as PictureUtil from "../utils/PictureUtil";


const pictureToBeTaken = ["1,1", "1,2", "2,1", "2,2", "4,1", "4,2", "5,1", "5,2", "7,1", "7,2", "8,1", "8,2", "1,8", "1,9", "2,8", "2,9", "4,8", "4,9", "5,8", "5,9", "7,8", "7,9", "8,8", "8,9", "1,15", "1,16", "2,15", "2,16", "4,15", "4,16", "5,15", "5,16", "7,15", "7,16", "8,15", "8,16"];

class Tile extends Component {

  constructor() {
    super();
    // this.state = {
    //   "override": false
    // }
  }

  // componentDidUpdate() {
  //   if (this.props.showSinglePic && this.props.tickingIndex === this.props.tileIndex && this.state.nextIterationStarted) {
  //     this.setState({ "nextIterationStarted": false });
  //   }
  // }
  // nextIteration = (instance) => {
  //   if (!instance.props.nextIterationStarted && instance.props.numberOfRounds > -1) {
  //     //instance.setState({ "override": true });
  //     setTimeout(instance.restartGame,1000,instance,false,0);

  //   }
  // }

  // restartGame = (instance,successFlag,timetaken) => {
  //   //instance.setState({"override": false });
  //   clearTimeout(instance.nextIteration);
  //     instance.props.restartGame(successFlag,instance.props.tileIndex,Math.round(timetaken));

  //   }


  getPictures = (value) => {
    const d = n => n > 9 ? d([...n.toString()].reduce((a, b) => +a + +b)) : n;

    switch (d(value)) {
      case 0: return "animals/guinea.png";
      case 1: return "animals/huron.png";
      case 2: return "animals/kangaroo.png";
      case 3: return "animals/lion.png";
      case 4: return "animals/oribi.png";
      case 5: return "animals/tortoise.png";
      case 6: return "animals/zebra.png";
      case 7: return "animals/gorilla.png";
      case 8: return "animals/dog.png";
      case 9: return "animals/cat.png";
      default: return "animals/kdud.png";
    }
  }
  render() {
    if (this.props.freezeWheel) {
      return <Box key={this.props.tileIndex} background="brand" > <Image
        className={this.props.tileIndexValue}
        onLoad={() => this.props.onImageLoad(this.props.tileIndex)}
        onError={() => this.props.onImageLoad(this.props.tileIndex)}
        draggable='false'
        fit="cover"
        style={{ opacity: "1" }}
        src={"https://optolite.mattengg.com/images/" + PictureUtil.getPictures(this.props.tileIndexValue, this.props.pictureType)}
      /></Box>
    } else if (this.props.override === true && this.props.showSinglePic && this.props.tickingIndex === this.props.tileIndex) {
      return <Box key={this.props.tileIndex} background="red" > </Box>;
    } else if (this.props.tickingIndex === this.props.tileIndex && !this.props.showSinglePic) {

      if (this.props.isImageTick) {
        return <Box key={this.props.tileIndex} background="brand" > <Image
          className={this.props.tileIndexValue}
          draggable='false'
          fit="cover"
          style={{ opacity: "1" }}
          src={"https://optolite.mattengg.com/images/" + PictureUtil.getPictures(this.props.tileIndexValue, this.props.pictureType)}
        /></Box>
      } else {
        return <Box key={this.props.tileIndex} background="green" > </Box>;
      }
    } else if (this.props.showSinglePic && this.props.tickingIndex === this.props.tileIndex) {
      // if(this.props.numberOfRounds > -1 && this.props.nextIterationStarted == true)  {
      // setTimeout(this.nextIteration, this.props.nextRoundWaitSpeed, this);
      // }
      let concatSingleElement = this.props.singleElement.row + "," + this.props.singleElement.column;
      if (pictureToBeTaken.includes(concatSingleElement)) {
        this.props.takePic(concatSingleElement);
      }
      let startTime = new Date();
      return <Box key={this.props.tileIndex} background="brand" > <Image
        style={
          {
            opacity: "1"
          }
        }
        fit="cover"
        onClick={() => {

          this.props.restartGame(true, this.props.tileIndexValue, new Date().getTime() - startTime.getTime(), this.props.tickingIndex);
        }}
        draggable='false'
        src={"https://optolite.mattengg.com/images/" + PictureUtil.getPictures(this.props.tileIndexValue, this.props.pictureType)}
      /></Box>;
    } else {
      return <Box key={this.props.tileIndex} background="brand" > </Box>;
    }
  }
}

export default Tile;
