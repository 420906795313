import {
  Box,
  Button,
  Collapsible, Grid,
  Grommet,
  Layer, ResponsiveContext
} from 'grommet';
import { FormClose, LinkPrevious } from 'grommet-icons';
import React, { Component } from 'react';
import LiveCapture from './compnents/LiveCapture';
import Tile from "./compnents/TileComponent";
import * as GameUtil from "./utils/GameUtil";
const theme = {
  global: {
    colors: {
      brand: '#9C27B0',
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
  },
};

const totalTiles = 128;

// const pictureToBeTaken= ["1,1","1,2","2,1","2,2","4,1","4,2","5,1","5,2","7,1","7,2","8,1","8,2","1,8","1,9","2,8","2,9","4,8","4,9","5,8","5,9","7,8","7,9","8,8","8,9","1,15","1,16","2,15","2,16","4,15","4,16","5,15","5,16","7,15","7,16","8,15","8,16"];
const pictureObject = [
  { isPictureTaken: false, values: ["1,1", "1,2", "2,1", "2,2"] },
  { isPictureTaken: false, values: ["4,1", "4,2", "5,1", "5,2"] },
  { isPictureTaken: false, values: ["7,1", "7,2", "8,1", "8,2"] },
  { isPictureTaken: false, values: ["1,8", "1,9", "2,8", "2,9"] },
  { isPictureTaken: false, values: ["4,8", "4,9", "5,8", "5,9"] },
  { isPictureTaken: false, values: ["7,8", "7,9", "8,8", "8,9"] },
  { isPictureTaken: false, values: ["1,15", "1,16", "2,15", "2,16"] },
  { isPictureTaken: false, values: ["4,15", "4,16", "5,15", "5,16"] },
  { isPictureTaken: false, values: ["7,15", "7,16", "8,15", "8,16"] }

]



class App extends Component {
  nextIterationTimer = "";
  tempTickIndex = 0;
  tempTileIndex = 0;
  singleElement = {};
  capturedImagesArray = [
    { isPictureTaken: false, values: ["1,1", "1,2", "2,1", "2,2"] },
    { isPictureTaken: false, values: ["4,1", "4,2", "5,1", "5,2"] },
    { isPictureTaken: false, values: ["7,1", "7,2", "8,1", "8,2"] },
    { isPictureTaken: false, values: ["1,8", "1,9", "2,8", "2,9"] },
    { isPictureTaken: false, values: ["4,8", "4,9", "5,8", "5,9"] },
    { isPictureTaken: false, values: ["7,8", "7,9", "8,8", "8,9"] },
    { isPictureTaken: false, values: ["1,15", "1,16", "2,15", "2,16"] },
    { isPictureTaken: false, values: ["4,15", "4,16", "5,15", "5,16"] },
    { isPictureTaken: false, values: ["7,15", "7,16", "8,15", "8,16"] }

  ]
  constructor(props) {
    super(props);
    let originalArray = GameUtil.shuffleArray([...Array(totalTiles).keys()]);
    //let originalArray = [...Array(totalTiles).keys()];

    this.state = {
      numberOfRounds: props.numberOfRounds,
      freezetime: 2,
      freezeWheel: true,
      showSidebar: false,
      countForTick: 0,
      arrayForTick: originalArray,
      changeArray: GameUtil.shuffleArray(GameUtil.arraysAllowedToTick(props.inspectionType, originalArray)),
      toBeTicked: 0,
      openPopup: false,
      resultList: [],
      nextIterationStarted: true,
      override: false,
      tickedTiles: [],
      pictureLoadArray: []

    }
  }

  takepicture = (concatSingleElement) => {
    if (this.props.inspectionType === "Full") {
      this.capturedImagesArray.filter(k => k.isPictureTaken === false && k.values.includes(concatSingleElement)).forEach(element => {
        let context = document.getElementById("canvasTrainer").getContext('2d');
        document.getElementById("canvasTrainer").width = 320;
        document.getElementById("canvasTrainer").height = 300;
        context.drawImage(document.getElementById("videoTrainer"), 0, 0, 320, 300);

        let data = document.getElementById("canvasTrainer").toDataURL('image/png');
        element.isPictureTaken = true;
        element.picture = data;
        element.point = concatSingleElement;
        //photoTrainer.setAttribute('src', data);
        //clearphoto();
      });

    }
  }

  getPictures = (value) => {
    const d = n => n > 9 ? d([...n.toString()].reduce((a, b) => +a + +b)) : n;

    switch (d(value)) {
      case 0: return "animals/guinea.png";
      case 1: return "animals/huron.png";
      case 2: return "animals/kangaroo.png";
      case 3: return "animals/lion.png";
      case 4: return "animals/oribi.png";
      case 5: return "animals/tortoise.png";
      case 6: return "animals/zebra.png";
      case 7: return "animals/gorilla.png";
      case 8: return "animals/dog.png";
      case 9: return "animals/cat.png";
      default: return "animals/kdud.png";
    }
  }
  shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  startTiles = (instance) => {
    instance.setState({ "freezeWheel": false });
    instance.gametimer(instance);
  }

  tickTiles = (instance) => {
    //let a = Object.assign([],changeArray);
    let s = this.state.changeArray.pop();
    //instance.setState({ "toBeTicked": s, "changeArray": a});
    var playPromise = instance.green.play()// instance ? instance.green ? instance.green.play() : '' : "";

    if (playPromise !== undefined) {
      playPromise.then(_ => {

        //    // video.pause();
      })
        .catch(error => {

        });
    }

    //alert('tick');
    //timerId = setTimeout(tick, 2000); // (*)
    let tickedTiles = this.state.tickedTiles;
    if (instance.state.changeArray.length === 0) {
      tickedTiles.push(s);
      clearTimeout(instance.state.timerWheel);
      instance.nextIterationTimer = setTimeout(instance.nextIteration, instance.props.nextRoundWaitSpeed, instance);

    }
    instance.setState({ "toBeTicked": s, tickedTiles });
  }

  nextIteration = (instance) => {
    instance.setState({ "override": true });
    setTimeout(instance.restartGame, 1000, false, instance.tempTickIndex, 0, instance.tempTileIndex);

  }
  componentWillUnmount() {
    clearTimeout(this.state.timerWheel);
    clearTimeout(this.nextIterationTimer);

  }
  checkIfAllImageLoaded(pictureLoadArray) {
    if (this.state.arrayForTick.length === pictureLoadArray.length) {
      setTimeout(this.startTiles, this.props.pictureWaitSpeed, this);
    }
  }

  gametimer(instance) {
    let timerWheel = setInterval(() => instance.tickTiles(instance), instance.props.tileRunSpeed);
    this.setState({
      timerWheel, numberOfRounds: this.state.numberOfRounds - 1, nextIterationStarted: true
    });
  }

  restartGame = (successFlag, imageIndex, timeTaken, tileIndex) => {
    let resultObject = { successFlag, imageIndex, timeTaken: (timeTaken / 1000).toFixed(2), pictureIndex: this.state.tileIndex, tileIndex, singleElement: this.singleElement };
    clearTimeout(this.nextIterationTimer);

    this.setState({ "override": false });
    this.state.resultList.push(resultObject);

    if (this.state.numberOfRounds > 0) {
      //this.changeArray = GameUtil.shuffleArray([...Array(totalTiles).keys()]);
      let originalArray = GameUtil.shuffleArray(GameUtil.shuffleArray([...Array(totalTiles).keys()]));
      let changeArray = GameUtil.shuffleArray(GameUtil.arraysAllowedToTick(this.props.inspectionType, originalArray));
      while (this.state.tickedTiles.includes(changeArray[changeArray.length - 1])) {
        changeArray = GameUtil.shuffleArray(GameUtil.arraysAllowedToTick(this.props.inspectionType, originalArray));
      }
      this.setState({
        changeArray: GameUtil.shuffleArray(GameUtil.arraysAllowedToTick(this.props.inspectionType, originalArray)),
        arrayForTick: originalArray
      });

      this.gametimer(this);
    } else {
      this.setState({ "numberOfRounds": this.state.numberOfRounds - 1 });

      this.openPopup(true);
    }

  }

  openPopup = (value) => {
    this.setState({ "openPopup": value });

  }
  render() {
    let a = [];
    let index = 0;
    //for (let index = 0; index < this.state.arrayForTick.length; index++) {
    for (let row = 1; row <= 8; row++) {
      for (let column = 1; column <= 16; column++) {

        if (this.state.changeArray.length === 0 ? true : false && this.state.toBeTicked === index) {
          this.tempTickIndex = this.state.arrayForTick[index];
          this.tempTileIndex = this.state.toBeTicked;
          if (this.state.toBeTicked === index) {
            this.singleElement = { row, column };

          }
        }
        a.push(<Tile key={index} tileIndex={index}
          takePic={this.takepicture}
          onImageLoad={(index) => {
            let pictureLoadArray = this.state.pictureLoadArray;
            pictureLoadArray.push(index);
            this.setState({ pictureLoadArray });
            this.checkIfAllImageLoaded(pictureLoadArray);
          }}
          tileIndexValue={this.state.arrayForTick[index]}
          freezeWheel={this.state.freezeWheel}
          tickingIndex={this.state.toBeTicked}
          nextRoundWaitSpeed={this.props.nextRoundWaitSpeed}
          restartGame={(successFlag, imageIndex, timeTaken) => this.restartGame(successFlag, imageIndex, timeTaken, index)}
          nextIterationStarted={this.state.nextIterationStarted}
          override={this.state.override}
          pictureType={this.props.pictureType}
          isImageTick={this.props.isImageTick}
          singleElement={{ row, column }}
          showSinglePic={this.state.changeArray.length === 0 ? true : false} />);
        // if(this.state.freezeWheel){
        //   a.push(<Box key={index} background="brand" > <Image
        //         fit="cover"
        //         style= {{opacity:"1"}}
        //   src={"http://localhost:3000/"+this.getPictures(this.state.arrayForTick[index])}
        // /></Box>);
        // }else if (this.state.toBeTicked === this.state.arrayForTick[index] && this.state.changeArray.length != 0) {
        //   a.push(<Box key={index} background="green" > </Box>)
        // } else if (this.state.changeArray.length == 0 && this.state.toBeTicked === this.state.arrayForTick[index]){
        //   // a.push(<Box key={index} background="brand" > <Image
        //   //   style={
        //   //     {
        //   //       background: this.state.toBeTicked === this.state.arrayForTick[index] ? "green !important" : "green",
        //   //       opacity: this.state.changeArray.length == 0 && this.state.toBeTicked === this.state.arrayForTick[index]  ? "1" : "0"
        //   //     }
        //   //   }
        //   //   fit="cover"
        //   //   onClick={this.state.changeArray.length == 0 && this.state.toBeTicked === this.state.arrayForTick[index] ? ()=>this.restartGame():null}
        //   //   src={"http://localhost:3000/"+this.getPictures(this.state.arrayForTick[index])}
        //   // /></Box>);
        //   a.push(<Box key={index} background="brand" > <Image
        //     style={
        //        {
        //      opacity: "1"
        //        }
        //        }
        //    fit="cover"
        //    onClick={()=>this.restartGame()}
        //     src={"http://localhost:3000/"+this.getPictures(this.state.arrayForTick[index])}
        //  /></Box>);
        // }else {
        //   a.push(<Box key={index} background="brand" > </Box>)
        // }
        index = index + 1;

      }
    }

    const { showSidebar } = this.state;
    return (
      <Grommet theme={theme} full>
        {this.props.inspectionType === "Full" && <LiveCapture />}
        <LinkPrevious onClick={() => { this.props.backButtonClick() }} style={{ margin: '1%', position: 'fixed' }} />
        <audio ref={(green) => { this.green = green; }} src={"tick.mp3"} />
        {/* <audio  src={"test.mp3"} autoPlay/> */}
        <ResponsiveContext.Consumer>
          {size => (
            <Box fill>
              {/* <AppBar>
              <Heading level='3' margin='none'>Eye Trainer</Heading>
  
            <Button
               icon={<Notification />}
               onClick={() => this.setState(prevState => ({ showSidebar: !prevState.showSidebar }))}
             />
            </AppBar> */}
              {/* <Box style= {{marginTop:'3%', display: 'inline'}} direction='row' flex overflow={{ horizontal: 'hidden' }}> */}
              <Box align='center' flex overflow={{ horizontal: 'hidden' }}>
                <Box flex align='center' justify='center'>
                  <Grid
                    rows={['xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall']}
                    columns={['xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall', 'xsmall']}
                    fill='horizontal'
                    gap="small"


                  >
                    {a}
                  </Grid>
                </Box>
                {(!showSidebar || size !== 'small') ? (
                  <Collapsible direction="horizontal" open={showSidebar}>
                    <Box
                      flex
                      width='medium'
                      background='light-2'
                      elevation='small'
                      align='center'
                      justify='center'
                    >
                      sidebar
                    </Box>
                  </Collapsible>
                ) : (
                  <Layer>
                    <Box
                      background='light-2'
                      tag='header'
                      justify='end'
                      align='center'
                      direction='row'
                    >
                      <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ showSidebar: false })}
                      />
                    </Box>
                    <Box
                      fill
                      background='light-2'
                      align='center'
                      justify='center'
                    >
                      sidebar
                    </Box>
                  </Layer>
                )}
              </Box>
            </Box>
          )}

        </ResponsiveContext.Consumer>
        <Box>
          {this.state.openPopup && (
            <Layer style={{ width: "30%", height: "30%" }}
              onEsc={() => this.openPopup(false)}
              onClickOutside={() => this.openPopup(false)}
            >
              <Box direction="column" align="center"
              >
                <h1><strong >GameOver</strong></h1>
                <Box style={{ paddingTop: "10%" }} direction="row">
                  <Button label="Show Results" onClick={() => {
                    this.openPopup(false);
                    this.props.resultPageChange(this.state.resultList, this.capturedImagesArray.filter(k => k.isPictureTaken));
                  }
                  } />
                </Box>
              </Box>

            </Layer>
          )}
        </Box>
      </Grommet>
    );
  }
}

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);

export default App;
