import { StyleSheet } from '@react-pdf/renderer';
import {
  Box, Grid,
  Grommet, Table,
  TableBody, TableCell, TableRow
} from 'grommet';
//import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, MarkSeries, HeatmapSeries, VerticalBarSeries,FlexibleXYPlot } from 'react-vis';
import { LinkPrevious, Print } from 'grommet-icons';
import React, { Component } from 'react';
// import {Card, CardHeader, CardBody} from 'grommet/components/Card';
import { Bar, BarChart, CartesianGrid, Label, Tooltip, XAxis, YAxis } from 'recharts';
import * as GameUtil from "../utils/GameUtil";
import * as PictureUtil from "../utils/PictureUtil";
import "./CommonStyle.css";


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const ref = React.createRef();
const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [4, 2]
};
const theme = {
  global: {
    colors: {
      brand: '#9C27B0',
    },
    table: {
      body: {
        pad: {
          "horizontal": "xxsmall",
          "vertical": "xxsmall"
        }
      }
    },

    font: {
      family: 'Trebuchet MS',
      size: '14px',
      height: '20px',
    }
  },
};
class OutputPage extends Component {

  constructor(props) {
    super(props);
    let successCount = 0;
    let failureCount = 0;
    let timeTakenCountList = [];
    let failedPictures = [];
    let successPictures = [];
    let failedPicturePlaces = [];
    let successPicturePlaces = [];
    let failedIndexCount = [];
    let successIndexCount = [];
    let successCordinateCount = [];
    let failureCordinateCount = [];
    let successObject = {};
    let graphDate = [];
    props.resultData.resultList.forEach(k => {
      if (k.successFlag === true) {
        successCount = successCount + 1;
        timeTakenCountList.push(k.timeTaken);
        successPictures.push(PictureUtil.getRefinedPictureName(PictureUtil.getPictures(k.imageIndex, props.resultData.pictureType).toString()));
        successPicturePlaces.push(GameUtil.getRowColumnIndex(k.imageIndex, 8, 16).remove);
        successIndexCount.push(k.tileIndex);
        successCordinateCount.push(k.singleElement.row + "," + k.singleElement.column)
        graphDate.push({ x: successCount, y: k.timeTaken });
        successObject[k.singleElement.row + "," + k.singleElement.column] = k.timeTaken;
      } else {
        failedPictures.push(PictureUtil.getRefinedPictureName(PictureUtil.getPictures(k.imageIndex, props.resultData.pictureType).toString()));
        failureCount = failureCount + 1;
        failedPicturePlaces.push(GameUtil.getRowColumnIndex(k.imageIndex, 8, 16));
        failedIndexCount.push(k.tileIndex);
        failureCordinateCount.push(k.singleElement.row + "," + k.singleElement.column)


      }
    })
    this.state = {
      successObject, failureCordinateCount, successCordinateCount, graphDate, failedIndexCount, successIndexCount, successCount, failureCount, timeTakenCountList, failedPictures, successPictures, successPicturePlaces, failedPicturePlaces
    }
  }
  render() {
    return <Grommet id="wrapper" theme={theme} full>

      <LinkPrevious onClick={() => { this.props.backButtonClick() }} style={{ margin: '1%', position: 'fixed' }} />
      <Print onClick={() => { window.print() }} style={{ cursor: 'pointer', right: "1%", margin: '1%', position: 'fixed' }} />

      <h1 style={{ marginTop: '2%', textAlign: 'center' }} ><strong >Training Results</strong></h1>

      {/* <ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options} x={.5} y={.5}>
        {({toPdf}) => (
            <button onClick={toPdf}>Generate pdf</button>
        )}
    </ReactToPdf> */}


      <Box ref={ref} gap="small" wrap direction="row" justify="start" border={{ color: 'brand', size: 'small' }}>
        <Box style={{ width: '50%' }}><Table>
          <TableBody>
            <TableRow >
              <TableCell ><strong>Trainee Name </strong></TableCell>
              <TableCell >{this.props.resultData.traineeName ? this.props.resultData.traineeName : "Test Trainee"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell ><strong>No of Rounds</strong></TableCell>
              <TableCell >{this.props.resultData.numberOfRounds}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Position</strong></TableCell>
              <TableCell>{this.props.resultData.inspectionType}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Category</strong></TableCell>
              <TableCell>{this.props.resultData.pictureType}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Scrolling Delay</strong></TableCell>
              <TableCell>{this.props.resultData.tileRunSpeed}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Preview Delay</strong></TableCell>
              <TableCell>{this.props.resultData.pictureWaitSpeed}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell ><strong>Image StandBy Time</strong></TableCell>
              <TableCell >{this.props.resultData.nextRoundWaitSpeed}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Success Count</strong></TableCell>
              <TableCell>{this.state.successCount}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Success Images</strong></TableCell>
              <TableCell>{this.state.successPictures.toString()}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Failure Count</strong></TableCell>
              <TableCell>{this.state.failureCount}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Failed Images</strong></TableCell>
              <TableCell>{this.state.failedPictures.toString()}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell><strong>Touch Time</strong></TableCell>
              <TableCell>{this.state.timeTakenCountList.toString()}</TableCell>
            </TableRow>
          </TableBody>
        </Table></Box>

        <Box style={{ padding: '3%' }}>
          {/* <XYPlot
          xType="ordinal"
          width={400}
          height={300} >
          <VerticalBarSeries

            data={this.state.graphDate}
          />
          <XAxis title="Success Count" />
          <YAxis title="Time Taken"  position="end" orientation="left"/>
        </XYPlot> */}
          <BarChart width={300} height={300} data={this.state.graphDate}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x"><Label value="Success Count" position="insideBottom" /></XAxis>
            <YAxis dataKey="y"><Label angle={-90} value="Time Taken" position="insideLeft" /></YAxis>
            <Tooltip />
            {/* <Tooltip />
  <Legend />  */}
            <Bar dataKey="y" fill="#9C27B0" barSize={25} />
          </BarChart>
        </Box>

      </Box>
      <div className="new-page"></div>

      <Box flex align='center' >
        <p><strong>Success Failure Model </strong></p>
        <Grid style={{ fontSize: "10px" }} border=""
          rows={['25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px']}
          columns={['25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px', '25px']}
          fill='horizontal'
          gap="xxsmall"
        >

          {[...Array(153).keys()].map(x => {
            let success = "0";
            let a = GameUtil.getRowColumnIndex(x, 9, 17);
            if (a.row !== 1 && a.column !== 1) {
              let b = (a.row - 1) + "," + (a.column - 1);
              if (this.state.successCordinateCount.includes(b)) {
                success = "1";
              } else if (this.state.failureCordinateCount.includes(b)) {
                success = "-1"
              }
            }
            return <Box wrap alignContent="center" align="center" justify="center"


              style={{
                WebkitPrintColorAdjust: 'exact', backgroundColor: success === "1"
                  ? 'green' : success === "-1" ? "red" : GameUtil.findColor(a.row, a.column)
              }}
              key={this.props.tileIndex}

            ><span>
                {GameUtil.findColor(a.row, a.column) === "white" ? (a.row - 1) + "," + (a.column - 1) : (this.state.successObject.hasOwnProperty((a.row - 1) + "," + (a.column - 1)) ? this.state.successObject[(a.row - 1) + "," + (a.column - 1)] : "")}</span>
            </Box>
          })}
        </Grid>
      </Box>
      {/* <div className="new-page"></div> */}
      <Box flex align='center' >
        <p><strong>Eye Position Captured Images</strong></p>
      </Box>
      <div class="box-pic">
        {this.props.resultData.capturedImagesArray.map((k, index) => (
          <div class="box-item-pic">
            <div>[row,column]={k.point.split(",").map(v => Number.parseInt(v) + 1).toString()}</div>
            <div><img src={k.picture} alt="The screen capture will appear in this box." /></div>
          </div>))}

      </div>
      {/* <Box pad="large">
         
        <Grid columns={'large' } gap="large">
          {this.props.resultData.capturedImagesArray.map((k, index) => (
            
            <Box pad="large" key={index}>
        <div>[row,column]={k.point}</div>
        <div align="center"><img src={k.picture} alt="The screen capture will appear in this box."/></div>
            </Box>
          ))}
        </Grid>
      </Box> */}

    </Grommet>
  }
}

export default OutputPage;